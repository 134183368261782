import React, { useState, useRef, useEffect } from 'react';
import { useNativeAppCreateProjectMutation } from 'app/createApi';
import { TrashIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectNativeAppName,
  selectSelectedModules,
  setNativeAppName,
  setSelectedModules,
} from 'features/native-app-builder/reducers/nativeAppSlice';
import {
  selectNewProjectName,
  selectSelectedGroup,
  selectProjectsCreatedInCurrentSession,
  setNewProjectName,
  setProjectsCreatedInCurrentSession,
} from 'app/sharedSlice';
import StepWrapper from 'components/StepWrapper';
import GroupAndProjectSelector, { sanitizeProjectName } from 'components/GroupAndProjectSelector';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import { modules } from './ListOfModules';
import type { IModuleType } from 'utils/types';
import { classNames } from 'utils/styleUtils';

export interface NativeAppDefinitionStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function NativeAppDefinitionStep(props: NativeAppDefinitionStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const nativeAppName = useAppSelector(selectNativeAppName);
  const selectedModules = useAppSelector(selectSelectedModules);

  const group = useAppSelector(selectSelectedGroup);
  const newProjectName = useAppSelector(selectNewProjectName);
  const projectsCreatedInCurrentSession = useAppSelector(selectProjectsCreatedInCurrentSession);

  const [activeModuleIndex, setActiveModuleIndex] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>('Failed to create project');

  const formRef = useRef<HTMLFormElement>(null);

  const [nativeAppCreateProject] = useNativeAppCreateProjectMutation();

  useEffect(() => {
    dispatch(setNewProjectName(sanitizeProjectName(nativeAppName)));
  }, [nativeAppName]);

  const addModule = (type: IModuleType): void => {
    const countOfType = selectedModules.filter((module) => module.type === type).length;
    dispatch(
      setSelectedModules([
        ...selectedModules,
        {
          type,
          name: `${nativeAppName.replaceAll(' ', '_').toLowerCase()}.${(type as string)
            .replaceAll(' ', '_')
            .toLowerCase()}_${countOfType + 1}`,
        },
      ]),
    );
  };

  const removeModule = (moduleName: string): void => {
    dispatch(setSelectedModules(selectedModules.filter((module) => module.name !== moduleName)));
  };

  // const removeModule = (moduleIdx: number): void => {
  //   dispatch(setSelectedModules(selectedModules.filter((_, index) => index !== moduleIdx)));
  // };

  const changeModuleName = (type: IModuleType, moduleIdx: number, name: string): void => {
    const tmpSelectedModules = [...selectedModules];
    let indexWithinType = 0;
    for (let i = 0; i < tmpSelectedModules.length; i++) {
      if (tmpSelectedModules[i].type !== type) {
        continue;
      }

      if (indexWithinType === moduleIdx) {
        console.log(tmpSelectedModules[i]);
        tmpSelectedModules[i] = {
          type,
          name,
        };
        break;
      }

      indexWithinType++;
    }

    dispatch(setSelectedModules(tmpSelectedModules));
  };

  const onContinue = (): void => {
    if (formRef.current?.reportValidity() === true) {
      const projectName = `${group.path}/${newProjectName}`;

      const projectAlredyCreatedInThisSession = projectsCreatedInCurrentSession.includes(projectName);
      if (projectAlredyCreatedInThisSession) {
        props.onContinue();
        return;
      }

      setLoading(true);
      setFailed(false);
      nativeAppCreateProject({
        projectName,
      })
        .unwrap()
        .then((res: any) => {
          dispatch(setProjectsCreatedInCurrentSession([...projectsCreatedInCurrentSession, projectName]));
          props.onContinue();
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
          // setErrorMessage(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getLogo = (moduleType: IModuleType): string => {
    switch (moduleType) {
      case 'Python UDF':
        return '/static/python-udf-logo.png';
      case 'Streamlit':
        return '/static/streamlit-logo.png';
      case 'SQL UDF':
        return '/static/sql-udf-logo.png';
      case 'Snowpark Container Services':
        return '/static/spcs-logo.png';
      case 'Snowflake AI':
        return '/static/snowflake-ai-logo.png';
      case 'Cortex AI':
        return '/static/snowflake-cortex-logo.png';
      case 'Cortex AI Search':
        return '/static/snowflake-cortex-logo.png';
      case 'Shared Data':
        return '/static/snowflake-sharing-logo.png';
      case 'Notebook':
        return '/static/notebook-logo.png';
      default:
        return '/static/snowflake-cortex-logo.png';
    }
  };

  return (
    <StepWrapper
      title="Native app definition"
      subtitle="Define the native app properties"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      isLoading={loading}
    >
      <div className="h-full">
        <form ref={formRef}>
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={nativeAppName}
                    onChange={(e) => dispatch(setNativeAppName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Advertising Spend Analysis"
                    required
                  />
                </div>
              </div>
            </div>
            <GroupAndProjectSelector showCreateNewProject={false} />
          </div>
        </form>

        <div className="w-4/5 mx-[auto] flex">
          <div className="w-1/2 flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Modules</span>
              </div>
            </div>
            <div className="w-full bg-gray-50 p-2">
              {modules.map((module, moduleIndex) => (
                <div
                  key={moduleIndex}
                  className={classNames(
                    'w-full max-w-4xl relative flex flex-col items-center p-2 mb-1 rounded-md text-gray-700 bg-white cursor-pointer',
                    activeModuleIndex === moduleIndex
                      ? 'border border-dataops-secondary-blue'
                      : 'border border-gray-300',
                  )}
                  onClick={() => setActiveModuleIndex(moduleIndex)}
                >
                  <div className="w-full flex items-center">
                    <img
                      className="h-10 w-10 object-contain p-1 rounded-full overflow-visible"
                      src={getLogo(module.name as IModuleType)}
                    />
                    <div className="grow truncate ml-1 mr-2 text-gray-800 text-base font-medium">{module.name}</div>
                    <button
                      type="button"
                      onClick={() => addModule(module.name as IModuleType)}
                      className="ml-2 rounded-full bg-dataops-secondary-blue px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400"
                      disabled={module.disabled}
                    >
                      Add
                    </button>
                  </div>
                  <ul className="w-full space-y-1">
                    {selectedModules
                      .filter((selectedModule) => selectedModule.type === module.name)
                      .map((selectedModule, selectedModuleIndex) => (
                        <li key={selectedModuleIndex} className="flex ml-3">
                          <input
                            type="text"
                            value={selectedModule.name}
                            onChange={(e) => changeModuleName(selectedModule.type, selectedModuleIndex, e.target.value)}
                            className="block rounded truncate mr-2 px-1 flex-1 border-[1px] border-transparent bg-transparent text-sm  text-gray-600 placeholder:text-gray-400 outline-0 focus-visible:border-gray-400 hover:border-gray-200"
                          />
                          {/* <div className="truncate text-sm mr-2 text-gray-600">{selectedModule.name}</div> */}
                          <button
                            type="button"
                            onClick={() => removeModule(selectedModule.name)}
                            className="ml-[auto] rounded-full bg-dataops-red p-1 text-white shadow-sm"
                          >
                            <TrashIcon className="h-4 w-4" aria-hidden="true" />
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Description of selected module */}
          <div className="w-1/2 mx-[auto] flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">{modules[activeModuleIndex].name}</span>
              </div>
            </div>
            <div className="w-full min-h-[20rem] p-2 bg-gray-50">
              <div className="h-full border border-gray-300 p-2 rounded-md text-sm bg-white">
                {modules[activeModuleIndex].disabled ? (
                  <div className="flex items-center">
                    <div className="text-base text-gray-600">Coming soon</div>
                    <ClockIcon className="w-5 h-5 ml-1 text-gray-600" />
                  </div>
                ) : (
                  modules[activeModuleIndex].description
                )}
              </div>
            </div>
          </div>

          {/* Module selector */}
          {/* <div className="w-1/2 flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Modules</span>
              </div>
            </div>
            {modules.map((module) => (
              <Disclosure key={module.name}>
                {({ open }) => (
                  <>
                    <div className="w-full max-w-4xl relative flex items-center p-2 pr-6 mb-1 border border-gray-300 rounded-md text-gray-700 bg-gray-50 cursor-default">
                      <div className="truncate mr-2 text-gray-800 text-base">{module.name}</div>
                      <button
                        type="button"
                        onClick={() => addModule(module.name as IModuleType)}
                        className="ml-2 rounded-full bg-dataops-secondary-blue px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-hover-secondary-blue"
                      >
                        Add
                      </button>
                      <Disclosure.Button className="absolute right-1 w-5 cursor-pointer">
                        <ChevronRightIcon
                          className={classNames(
                            open ? 'transform rotate-90' : '',
                            'w-5 h-5 ml-auto text-gray-700 transition duration-150 ease-in-out',
                          )}
                        />
                      </Disclosure.Button>
                    </div>

                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacitsy-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="w-full"
                    >
                      <Disclosure.Panel className="p-1.5 rounded-sm border border-gray-300 mb-1">
                        <div className="text-sm text-gray-600 text-left">{module.description}</div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </div> */}

          {/* Preview of selected modules */}
          {/* <div className="w-1/2 mx-[auto] flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Selected</span>
              </div>
            </div>

            {selectedModules.map((module: IModule, moduleIdx) => (
              <div
                key={`${moduleIdx}`}
                className="w-full max-w-4xl relative p-2 mb-1 border border-gray-300 rounded-md text-gray-700 bg-gray-50 cursor-default space-y-2"
              >
                <div className="flex items-center">
                  <div className="truncate mr-2 text-gray-800 text-base">{module.type}</div>
                  <button
                    type="button"
                    onClick={() => removeModule(moduleIdx)}
                    className="ml-2 rounded-full bg-red-500 px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
                <div className="flex shadow-sm w-full">
                  <span className="inline-flex items-center border border-r-0 rounded-l-md border-gray-300 bg-gray-50 px-2 text-gray-500 text-xs">
                    Name
                  </span>
                  <input
                    required
                    type="text"
                    value={module.name}
                    onChange={(e) => changeModuleName(moduleIdx, e.target.value)}
                    className="relative truncate grow block appearance-none border border-gray-300 rounded-r-md px-3 py-1 text-gray-900 placeholder-gray-500 text-xs"
                  />
                </div>
              </div>
            ))}
            {selectedModules.length === 0 && (
              <div className="text-sm text-gray-500 text-center">No modules selected</div>
            )}
            </div> */}
        </div>

        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
    </StepWrapper>
  );
}
