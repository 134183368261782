import React, { useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import {
  selectL1BusinessCapability,
  selectFormat,
  setFormat,
  selectDataOffice,
  setDataOffice,
  selectInformationSecurityClass,
  selectApplication,
  selectGradCode,
  setGradCode,
  selectBusinessFunction,
  selectBusinessCapability,
  selectFairProfileName,
  selectUseCase,
  setUseCase,
  selectUrl,
  setUrl,
  selectSourceSystem,
  setSourceSystem,
  selectSpecification1,
  setSpecification1,
  selectPurpose,
  setPurpose,
  selectBusinessTerm,
  setBusinessTerm,
  selectBusinessTermDefinition,
  setBusinessTermDefinition,
  selectName,
  selectDescription,
  setDescription,
  selectVersion,
  selectOwner,
  selectJiraTicketNo,
  setJiraTicketNo,
  selectDataProductManager,
  selectVersionDescription,
  selectContainsPatientData,
  selectContainsPersonalData,
  selectIsGxpValidated,
  selectIsSoxControlled,
  selectType,
  selectDataProductType,
} from '../reducers/astraZenecaSlice';
import { useAstraZenecaMetadataMutation } from 'app/createApi';
import StepWrapper from 'components/StepWrapper';
import DropdownSingleselect from 'components/DropdownSingleselect';
import DividerWithText from 'components/DividerWithText';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';

export interface DataProductDefinitionStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DataProductDefinition2Step(props: DataProductDefinitionStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const projectName = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);

  const l1BusinessCapability = useAppSelector(selectL1BusinessCapability);
  const format = useAppSelector(selectFormat);
  const dataOffice = useAppSelector(selectDataOffice);
  const informationSecurityClass = useAppSelector(selectInformationSecurityClass);
  const application = useAppSelector(selectApplication);
  const gradCode = useAppSelector(selectGradCode);
  const businessFunction = useAppSelector(selectBusinessFunction);
  const businessCapability = useAppSelector(selectBusinessCapability);
  const fairProfileName = useAppSelector(selectFairProfileName);
  const useCase = useAppSelector(selectUseCase);
  const url = useAppSelector(selectUrl);
  const businessTerm = useAppSelector(selectBusinessTerm);
  const businessTermDefinition = useAppSelector(selectBusinessTermDefinition);

  const name = useAppSelector(selectName);
  const description = useAppSelector(selectDescription);
  const version = useAppSelector(selectVersion);
  const owner = useAppSelector(selectOwner);
  const jiraTicketNo = useAppSelector(selectJiraTicketNo);
  const dataProductManager = useAppSelector(selectDataProductManager);
  const versionDescription = useAppSelector(selectVersionDescription);
  const type = useAppSelector(selectType);
  const dataProductType = useAppSelector(selectDataProductType);
  const containsPatientData = useAppSelector(selectContainsPatientData);
  const containsPersonalData = useAppSelector(selectContainsPersonalData);
  const isGxpValidated = useAppSelector(selectIsGxpValidated);
  const isSoxControlled = useAppSelector(selectIsSoxControlled);
  const purpose = useAppSelector(selectPurpose);
  const specification1 = useAppSelector(selectSpecification1);
  const sourceSystem = useAppSelector(selectSourceSystem);

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>(
    'Oops! Failed to save the data product attributes. Please try again or contact support@dataops.live if the issue persists.',
  );

  const [astraZenecaMetadata] = useAstraZenecaMetadataMutation();

  const formRef = useRef<HTMLFormElement>(null);

  const onContinue = (): void => {
    if (formRef.current?.reportValidity() === true) {
      setLoading(true);
      setFailed(false);

      astraZenecaMetadata({
        projectName,
        branch,
        name,
        description,
        jiraTicketNo,
        owner,
        dataProductManager,
        version,
        versionDescription,
        type,
        dataProductType,
        containsPatientData,
        containsPersonalData,
        isGxpValidated,
        isSoxControlled,
        purpose,
        specification1,
        sourceSystem,
        l1BusinessCapability,
        format,
        dataOffice,
        informationSecurityClass,
        application,
        gradCode,
        businessFunction,
        businessCapability,
        fairProfileName,
        useCase,
        url,
        businessTerm,
        businessTermDefinition,
      })
        .unwrap()
        .then((res: any) => {
          props.onContinue();
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
          // Currently we use a generic error message and not the one returned by the backend
          // setErrorMessage(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <StepWrapper
      title="Extended Data Product attributes"
      subtitle="The form fields are optional"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      isLoading={loading}
    >
      <div className="h-full">
        <form ref={formRef}>
          <div className="mx-[auto] w-3/4 px-2 pb-6 grid grid-cols-2 gap-6">
            <div className="col-span-2 mt-2">
              <DividerWithText dividerText="Optional attributes" />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                JIRA Ticket No
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={jiraTicketNo}
                    onChange={(e) => dispatch(setJiraTicketNo(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Purpose
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white">
                  <input
                    type="text"
                    value={purpose}
                    onChange={(e) => dispatch(setPurpose(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="decription"
                  name="description"
                  value={description}
                  onChange={(e) => dispatch(setDescription(e.target.value))}
                  rows={4}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  placeholder="This data product consolidates customer interaction data across multiple channels – including sales, customer service, and online engagement – to provide a comprehensive view of customer behaviours, preferences, and feedback. We will use this dashboard to segment our customer base, identify trends, and tailor our marketing strategies effectively and make data-driven decisions that enhance customer satisfaction and drive business growth."
                />
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Source system
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white">
                  <input
                    type="text"
                    value={sourceSystem}
                    onChange={(e) => dispatch(setSourceSystem(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Use Case
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white">
                  <input
                    type="text"
                    value={useCase}
                    onChange={(e) => dispatch(setUseCase(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Business Term
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white">
                  <input
                    type="text"
                    value={businessTerm}
                    onChange={(e) => dispatch(setBusinessTerm(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Business Term Definition
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white">
                  <input
                    type="text"
                    value={businessTermDefinition}
                    onChange={(e) => dispatch(setBusinessTermDefinition(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                URL
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white">
                  <input
                    type="text"
                    value={url}
                    onChange={(e) => dispatch(setUrl(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Specification 1
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white">
                  <input
                    type="text"
                    value={specification1}
                    onChange={(e) => dispatch(setSpecification1(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 mt-2">
              <DividerWithText dividerText="Read-only attributes" />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="Format"
                options={['RDBMS']}
                selected={format}
                setSelected={(value: string) => dispatch(setFormat(value))}
                disabled={true}
              />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="Data Office"
                options={['Operations Data Office']}
                selected={dataOffice}
                setSelected={(value: string) => dispatch(setDataOffice(value))}
                disabled={true}
              />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="GRAD Code"
                options={['GRAD - 0000']}
                selected={gradCode}
                setSelected={(value: string) => dispatch(setGradCode(value))}
                disabled={true}
              />
            </div>
          </div>
        </form>
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} hideLoading={true} />
      </div>
    </StepWrapper>
  );
}
