import React from 'react';
import { ChartBarIcon, ClockIcon, ReceiptRefundIcon, UsersIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import StepWrapper from 'components/StepWrapper';
import ComingSoonBadge from 'components/ComingSoonBadge';
import { classNames } from 'utils/styleUtils';

const templates = [
  {
    title: 'Build with a wizard',
    description:
      'Craft your Native app effortlessly with our intuitive wizard, guiding you step-by-step through each building phase. Select your desired components, construct, test, and deploy your app within minutes.',
    icon: ReceiptRefundIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    disabled: false,
  },
  {
    title: 'Advertising Spend Analysis',
    description:
      'The template provides data-driven tracking, analysis, and optimization of advertising expenditures for maximum effectiveness.',
    icon: CurrencyDollarIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    disabled: true,
  },
  {
    title: 'Customer 360 Dashboard',
    description:
      'The template offers a holistic view of customer interactions and behaviors, enabling businesses to make informed decisions and improve customer experience.',
    icon: UsersIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    disabled: true,
  },
  {
    title: 'Telco Network Performance',
    description:
      'The template provides real-time monitoring and analysis of telecommunications network performance to ensure optimal service quality.',
    icon: ChartBarIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    disabled: true,
  },
  {
    title: 'Video Transcription',
    description:
      'This Native App takes raw videos files from an S3 bucket, transcodes them using the whisper model on SPCS, stored the transcriptions and then uses a Streamlit app, powered by Cortex, to allow users to interact with the results.',
    icon: ClockIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    disabled: true,
  },
  {
    title: 'Energy Price Forecasting',
    description:
      'This Native App deploys an AI/ML time series forecasting model Snowflake AI. Data Scientists can use the embedded Notebook to refine the models. Business users to re-run the forecasting models to make Energy buying decisions.',
    icon: ReceiptRefundIcon,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    disabled: true,
  },
  // {
  //   title: 'Marketing Data Foundation Starter',
  //   description:
  //     'It can be challenging to integrate diverse sources of Marketing data. This starter Native App tackles this challenge by offering two independent solutions and a merged version, demonstrating how to integrate both solutions into a comprehensive &apos;marketing app suite&apos; - Marketing Data Foundation Starter (Merges the two apps below into a ‘marketing app suite’) - Data Foundation Starter for Customer 360- Data Foundation Starter for Campaign Intelligence. This solution was inspired by how Snowflake runs its own end-to-end Marketing workflows entirely on top of the Snowflake Marketing Data Cloud.',
  //   icon: AcademicCapIcon,
  //   iconForeground: 'text-indigo-700',
  //   iconBackground: 'bg-indigo-50',
  // },
];

export interface TemplateStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function TemplateStep(props: TemplateStepProps): JSX.Element {
  const [selectedTemplate, setSelectedTemplate] = React.useState<number>();

  return (
    <StepWrapper
      title="Template"
      subtitle="Choose a template for your native app"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={selectedTemplate === undefined}
    >
      <div className="h-full">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {templates.map((template, templateIdx) => (
            <div
              key={template.title}
              className={classNames(
                templateIdx === selectedTemplate ? 'bg-gray-50' : 'bg-white',
                templateIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                templateIdx === 1 ? 'sm:rounded-tr-lg' : '',
                templateIdx === templates.length + 1 - 2 ? 'sm:rounded-bl-lg' : '',
                templateIdx === templates.length + 1 - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'group relative p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 cursor-pointer',
              )}
              onClick={() => {
                if (!template.disabled) {
                  setSelectedTemplate(templateIdx);
                }
              }}
            >
              <div>
                <span
                  className={classNames(
                    template.iconBackground,
                    template.iconForeground,
                    'inline-flex rounded-lg p-3 ring-4 ring-white',
                  )}
                >
                  <template.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <div className="flex flex-col lg:flex-row lg:space-x-2">
                  <h3
                    className={classNames(
                      template.disabled ? 'text-gray-500' : 'text-gray-900',
                      'text-base font-semibold leading-6',
                    )}
                  >
                    {template.title}
                  </h3>
                  {template.disabled && <ComingSoonBadge />}
                </div>
                <p className={classNames(template.disabled ? 'text-gray-400' : 'text-gray-500', 'mt-2 text-sm')}>
                  {template.description}
                </p>
              </div>
              <span
                className={classNames(
                  template.disabled ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-500',
                  'pointer-events-none absolute right-6 top-6',
                )}
                aria-hidden="true"
              >
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </div>
    </StepWrapper>
  );
}
