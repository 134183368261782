import React from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';

export default function ComingSoonBadge(): JSX.Element {
  return (
    <span className="inline-flex w-fit items-center rounded-md bg-purple-50 px-1.5 py-0.5 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
      <div>Coming soon</div>
      <ClockIcon className="w-4 h-4 ml-1" />
    </span>
  );
}
