import React, { useMemo, useState, useEffect } from 'react';
import { Switch, Field, Label } from '@headlessui/react';
import { useGetDatabasesQuery } from 'app/createApi';
import { useAppSelector } from 'app/hooks';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import { classNames } from 'utils/styleUtils';

const azDatabaseList = ['ENTDATA_PUB', 'OPS_PUB', 'OPS_WRK', 'OPS_DP_PUB'];

interface DatabaseSelectorProps {
  onSelect: (database: string) => void;
  selectedDatabases: string[];
  onlyShowDatabases?: boolean;
}

export default function AzDatabaseSelector(props: DatabaseSelectorProps): JSX.Element {
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);

  const { data: databases, isLoading, isError, error } = useGetDatabasesQuery({ account: snowflakeAccount });

  const [searchTerm, setSearchTerm] = useState('');
  const [showAllDatabases, setShowAllDatabases] = useState(false);
  const [azDatabasesPresent, setAzDatabasesPresent] = useState(
    (databases ?? []).some((database) => azDatabaseList.includes(database)),
  );

  useEffect(() => {
    if (databases === undefined) {
      return;
    }

    setAzDatabasesPresent(databases.some((database) => azDatabaseList.includes(database)));
  }, [databases]);

  const filteredAnsSortedDatabases = useMemo(() => {
    if (databases === undefined) {
      return [];
    }

    const preFilteredDatabases =
      azDatabasesPresent && !showAllDatabases
        ? databases.filter((database) => azDatabaseList.includes(database))
        : databases;

    return preFilteredDatabases
      .filter((database) => database.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }, [databases, searchTerm, azDatabasesPresent, showAllDatabases]);

  return (
    <>
      {!isError && (
        <div className="">
          {azDatabasesPresent && props.onlyShowDatabases !== true && (
            <Field as="div" className="col-span-2 flex items-center justify-center mb-4">
              <Switch
                checked={showAllDatabases}
                onChange={() => setShowAllDatabases(!showAllDatabases)}
                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
              >
                <span className="sr-only">Use setting</span>
                <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                <span
                  aria-hidden="true"
                  className={classNames(
                    showAllDatabases ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                  )}
                />
                <span
                  aria-hidden="true"
                  className={classNames(
                    showAllDatabases ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                  )}
                />
              </Switch>
              <Label as="span" className="ml-1 cursor-default">
                <span className="text-sm font-medium text-gray-700">Show all databases</span>
              </Label>
            </Field>
          )}
          {props.onlyShowDatabases !== true && (
            <div className="mx-[auto] w-3/4 md:w-1/2 xl:w-1/3 rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
              <input
                type="text"
                id="search"
                name="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoComplete="search"
                placeholder="Search"
                className="block w-full flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                required
              />
            </div>
          )}
          <div className="mt-4 mb-4 space-y-1 flex flex-col">
            {filteredAnsSortedDatabases?.map((database) => (
              <div
                key={database}
                className={classNames(
                  props.selectedDatabases.includes(database)
                    ? 'text-gray-900 bg-gray-300 border-gray-500 hover:bg-gray-400'
                    : 'text-gray-700 border-gray-300 bg-gray-50 hover:bg-gray-100',
                  'w-3/4 flex py-1 px-4 rounded-md cursor-pointer border ',
                )}
                onClick={() => {
                  props.onSelect(database);
                }}
              >
                <div className="min-w-[20px] w-5">
                  <img className="w-5" src="/static/database-icon.svg" alt="Database" />
                </div>
                <div className="text-sm truncate">{database}</div>
              </div>
            ))}
            {isLoading && (
              <div className="min-h-8 flex items-center justify-center">
                <div
                  className="animate-spin inline-block w-8 h-8 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {!isLoading && filteredAnsSortedDatabases?.length === 0 && (
              <div className="text-sm text-gray-500">No databases found</div>
            )}
          </div>
        </div>
      )}
      <LoadingAndErrorSection
        isLoading={false}
        isFailed={isError}
        errorMessage={
          error !== undefined && 'data' in (error as any) ? (error as any).data : 'Failed to load databases'
        }
      />
    </>
  );
}
