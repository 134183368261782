import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce/AuthContext';
import type { IAuthContext } from 'react-oauth2-code-pkce';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchUser, selectEmail } from '../reducers/userSlice';
import LoadingView from 'views/LoadingView';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

interface ProtectedRouteProps {
  children: JSX.Element;
}

export default function ProtectedRoute(props: ProtectedRouteProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { token, login, loginInProgress } = useContext<IAuthContext>(AuthContext);
  const tokenRef = useRef(token);
  const email = useAppSelector(selectEmail);
  const [emailDomainAuthorized, setEmailDomainAuthorized] = useState(false);
  const deploymentEnv = process.env.REACT_APP_ENV;
  const [axiosBearerSet, setAxiosBearerSet] = useState(false);
  const [showUnauthorizedMessage, setShowUnauthorizedMessage] = useState(false);

  function loginWithRetry(retrigger: boolean): void {
    // Already logged in, nothing to do
    if (tokenRef.current !== '') {
      return;
    }

    if (!loginInProgress || retrigger) {
      login();
    }

    setTimeout(() => {
      loginWithRetry(true);
    }, 5000);
  }

  useEffect(() => {
    tokenRef.current = token;
    loginWithRetry(false);
  }, [token]);

  useEffect(() => {
    if (token !== '') {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      setAxiosBearerSet(true);
    }
  }, [token]);

  useEffect(() => {
    if (token !== '') {
      dispatch(fetchUser(token)).catch((err) => err);
    }
  }, [token]);

  useEffect(() => {
    if (email !== '') {
      if (
        deploymentEnv === 'az' &&
        !(
          email.endsWith('@astrazeneca.com') ||
          email.endsWith('@dataops.live') ||
          email.endsWith('@imaginarycloud.com')
        )
      ) {
        setEmailDomainAuthorized(false);
        setShowUnauthorizedMessage(true);
      } else {
        setEmailDomainAuthorized(true);
      }
    }
  }, [email]);

  if ((token === '' || !axiosBearerSet || email === '' || !emailDomainAuthorized) && !showUnauthorizedMessage) {
    return <LoadingView />;
  }

  if (showUnauthorizedMessage) {
    return (
      <div className="mt-[7rem] w-[25rem] mx-[auto] rounded-md bg-red-50 p-4 border-[1px] border-red-700">
        <div className="flex">
          <div className="shrink-0">
            <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-red-400" />
          </div>
          <div className="ml-3 text-sm font-medium text-red-800">You are not authorized to view this site</div>
        </div>
      </div>
    );
  }

  return props.children;
}
