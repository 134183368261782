import React, { useState, useEffect } from 'react';
import {
  TrashIcon,
  PencilSquareIcon,
  PlusIcon,
  DocumentIcon,
  ClipboardIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { Switch, Field, Label } from '@headlessui/react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { cloneDeep } from 'lodash';
import {
  useGetSqlPathsQuery,
  useLazyGetModelQuery,
  useSetModelMutation,
  useDeleteModelMutation,
  useGenerateDbtSourcesMutation,
  useValidateModelMutation,
  useGetDefaultDatabaseQuery,
} from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import { selectDataProductName } from 'features/data-product-builder/reducers/builderSlice';
import { selectSnowflakeAccount, selectSelectedDatabase, setSelectedDatabase } from 'app/snowflakeSlice';
import {
  selectModels,
  setModels,
  selectReviewedByInformationArchitect,
  setReviewedByInformationArchitect,
} from '../reducers/astraZenecaSlice';
import StepWrapper from 'components/StepWrapper';
import AzDatabaseSelector from '../components/AzDatabaseSelector';
import DropdownSingleselect from 'components/DropdownSingleselect';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import InformationTooltip from 'components/InformationTooltip';
import ModelErrorIcon from '../components/ModelErrorIcon';
import { getSnakeCase } from 'utils/formatUtils';
import { classNames } from 'utils/styleUtils';
import type { ISqlModel } from '../types/sqlBuilderTypes';

export interface SqlModelBuilderStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SqlModelBuilderStep(props: SqlModelBuilderStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const models = useAppSelector(selectModels);
  const dataProductName = useAppSelector(selectDataProductName);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const reviewedByInformationArchitect = useAppSelector(selectReviewedByInformationArchitect);

  const [filename, setFilename] = useState<string | undefined>();
  const [selectedModelIndex, setSelectedModelIndex] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [isValidateAllCompleted, setIsValidateAllCompleted] = useState<boolean>(false);

  const [validationInProgress, setValidationInProgress] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  const [setModel] = useSetModelMutation();
  const [deleteModel] = useDeleteModelMutation();
  const [generateDbtSources] = useGenerateDbtSourcesMutation();
  const [validateModel] = useValidateModelMutation();

  const { data: defaultDatabase } = useGetDefaultDatabaseQuery({ projectPath, branch });

  const { data: sqlPaths, isLoading } = useGetSqlPathsQuery({ projectPath, branch });
  console.log('sqlPaths: ', sqlPaths);

  const [getModel] = useLazyGetModelQuery();

  useEffect(() => {
    if (defaultDatabase !== undefined && defaultDatabase !== '' && selectedDatabase === '') {
      dispatch(setSelectedDatabase(defaultDatabase));
    }
  }, [defaultDatabase]);

  useEffect(() => {
    if (sqlPaths === undefined && !isLoading && models === undefined) {
      dispatch(setModels([]));
    }

    if (sqlPaths !== undefined && models === undefined) {
      dispatch(
        setModels(
          sqlPaths.map((sqlPath) => ({
            name: sqlPath
              .split('/')
              [sqlPath.split('/').length - 1].replace(/^v_|^m_|^i_/, '')
              .replace('.sql', ''),
            path: sqlPath,
            sql: undefined,
            materialization: sqlPath.includes('/materialized/')
              ? 'materialized'
              : sqlPath.includes('/virtualized/')
              ? 'virtualized'
              : 'incremental',
            sqlValidationStatus: 'unevaluated',
            modelNameValidationStatus: 'unevaluated',
          })),
        ),
      );
    }
  }, [sqlPaths, isLoading]);

  useEffect(() => {
    if (
      models === undefined ||
      models.length === 0 ||
      selectedModelIndex === undefined ||
      selectedModelIndex >= models.length
    ) {
      return;
    }

    setFilename(undefined);

    if (models[selectedModelIndex].sql === undefined) {
      getModel(
        {
          projectPath,
          branch,
          dbtPath: models[selectedModelIndex].path,
        },
        true,
      )
        .unwrap()
        .then((response) => {
          const newModels = models.map((model, index) => {
            if (index === selectedModelIndex) {
              return {
                ...model,
                sql: response,
              };
            }
            return model;
          });
          dispatch(setModels(newModels));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedModelIndex]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target !== null) {
          onChangeModelSql(event.target.result as string);
        }
      };
      reader.readAsText(e.target.files[0]);
      setFilename(e.target.files[0].name);
      e.target.value = '';
    }
  };

  const onCreateNewModel = (): void => {
    if (models === undefined) {
      return;
    }

    let newModelIndex = 1;
    const newModelPattern = /^schema__object_(?<number>\d+)$/;
    for (const model of models) {
      const match = model.name.match(newModelPattern);
      if (match !== undefined) {
        const number = match?.groups?.number;
        if (number !== undefined) {
          newModelIndex = Math.max(parseInt(number) + 1, newModelIndex);
        }
      }
    }

    setSelectedModelIndex(models.length);
    dispatch(
      setModels([
        ...models,
        {
          name: `schema__object_${newModelIndex}`,
          path: `dataops/modelling/models/virtualized/${getSnakeCase(
            dataProductName,
          )}/v_schema__object_${newModelIndex}.sql`,
          sql: '',
          materialization: 'virtualized',
          sqlValidationStatus: 'unevaluated',
          modelNameValidationStatus: 'unevaluated',
        },
      ]),
    );

    setIsValidateAllCompleted(false);
  };

  const onChangeModelName = (name: string): void => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    const newModels = cloneDeep(models);
    newModels[selectedModelIndex].name = name;
    const path = newModels[selectedModelIndex].path.split('/');
    path[path.length - 1] = `${
      newModels[selectedModelIndex].materialization === 'virtualized'
        ? 'v_'
        : newModels[selectedModelIndex].materialization === 'materialized'
        ? 'm_'
        : 'i_'
    }${name}.sql`;
    newModels[selectedModelIndex].path = path.join('/');
    newModels[selectedModelIndex].modelNameValidationStatus = 'unevaluated';
    dispatch(setModels(newModels));

    setIsValidateAllCompleted(false);
  };

  const onChangeModelSql = (sql: string): void => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    const newModels = cloneDeep(models);
    newModels[selectedModelIndex].sql = sql;
    newModels[selectedModelIndex].sqlValidationStatus = 'unevaluated';
    dispatch(setModels(newModels));

    if (failed && errorMessages[0] === `Model ${models[selectedModelIndex].name} is invalid`) {
      setFailed(false);
      setErrorMessages([]);
    }

    setIsValidateAllCompleted(false);
  };

  const onChangeMaterialization = (materialization: string): void => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    const newModels = cloneDeep(models);
    newModels[selectedModelIndex].materialization = materialization as 'materialized' | 'virtualized' | 'incremental';
    const path = newModels[selectedModelIndex].path.split('/');
    path[path.length - 2] = materialization;
    if (materialization === 'materialized') {
      newModels[selectedModelIndex].path = path.join('/').replace(/\/v_|\/i_/, '/m_');
    } else if (materialization === 'virtualized') {
      newModels[selectedModelIndex].path = path.join('/').replace(/\/m_|\/i_/, '/v_');
    } else if (materialization === 'incremental') {
      newModels[selectedModelIndex].path = path.join('/').replace(/\/m_|\/v_/, '/i_');
    }
    dispatch(setModels(newModels));

    setIsValidateAllCompleted(false);
  };

  const onDeleteModel = (model: ISqlModel): void => {
    if (models === undefined) {
      return;
    }

    const indexOfDeletedModel = models.findIndex((m) => m.path === model.path);
    if (selectedModelIndex === indexOfDeletedModel) {
      setSelectedModelIndex(undefined);
    } else if (selectedModelIndex !== undefined && selectedModelIndex > indexOfDeletedModel) {
      setSelectedModelIndex(selectedModelIndex - 1);
    }

    dispatch(setModels(models.filter((m) => m.path !== model.path)));
  };

  const validateModelSql = async (sql: string): Promise<void> => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    setValidationInProgress(true);
    const newModels = cloneDeep(models);

    const nonSelectCommands = ['create', 'drop', 'insert', 'update', 'delete', 'alter', 'truncate', 'grant', 'revoke'];
    if (sql !== undefined && sql !== '') {
      for (const command of nonSelectCommands) {
        const regex = new RegExp(`\\b${command}\\b`, 'i'); // Create a regex with word boundaries
        if (regex.test(sql)) {
          newModels[selectedModelIndex].sqlValidationStatus = 'invalid';
          newModels[selectedModelIndex].sqlValidationErrorMessage = 'The SQL contains a non-SELECT command';
          setValidationInProgress(false);
          dispatch(setModels(newModels));
          return;
        }
      }
    }

    try {
      const res = await validateModel({
        account: snowflakeAccount,
        database: selectedDatabase,
        sql,
      }).unwrap();
      console.log(res);
      newModels[selectedModelIndex].sqlValidationStatus = 'valid';
    } catch (err: any) {
      console.log(err);
      newModels[selectedModelIndex].sqlValidationStatus = 'invalid';
      newModels[selectedModelIndex].sqlValidationErrorMessage = err.data as string;
    }

    dispatch(setModels(newModels));

    setValidationInProgress(false);
  };

  const onCopy = async (): Promise<void> => {
    try {
      if (models !== undefined && selectedModelIndex !== undefined) {
        await navigator.clipboard.writeText(models[selectedModelIndex].sqlValidationErrorMessage as string);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onValidateAll = async (): Promise<void> => {
    if (models === undefined) {
      return;
    }

    setLoading(true);
    setFailed(false);
    const validationErrors = [];
    const updatedModels = cloneDeep(models);

    const placeholderNamePattern = /^schema__object_[0-9]+$/;
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (placeholderNamePattern.test(model.name)) {
        validationErrors.push(
          `Model names must adhere to the AZ naming standards. Please correct the name format for ${model.name}`,
        );
        updatedModels[modelIndex].modelNameValidationStatus = 'invalid';
        setFailed(true);
      }
    }

    const modelNamePattern = /^[a-zA-Z0-9_]+__[a-zA-Z0-9_]+$/;
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (!modelNamePattern.test(model.name)) {
        validationErrors.push(`Model name ${model.name} is not matching the pattern {schema_name}__{object_name}`);
        setFailed(true);
        updatedModels[modelIndex].modelNameValidationStatus = 'invalid';
      }
    }

    // If the SQL ends with a semicolon, remove it
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (model.sql !== undefined && model.sql !== '') {
        let sql = model.sql.trimEnd();
        if (sql.endsWith(';')) {
          sql = sql.replace(/;$/, '');
        }
        updatedModels[modelIndex].sql = sql;
      }
    }

    const nonSelectCommands = ['create', 'drop', 'insert', 'update', 'delete', 'alter', 'truncate', 'grant', 'revoke'];
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (model.sql !== undefined && model.sql !== '') {
        const sql = model.sql.toLowerCase();
        for (const command of nonSelectCommands) {
          const regex = new RegExp(`\\b${command}\\b`, 'i'); // Create a regex with word boundaries
          if (regex.test(sql)) {
            validationErrors.push(`Model ${model.name} contains a non-SELECT command`);
            setFailed(true);
            updatedModels[modelIndex].sqlValidationStatus = 'invalid';
            updatedModels[modelIndex].sqlValidationErrorMessage = 'The SQL contains a non-SELECT command';
          }
        }
      }
    }

    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (model.sql !== undefined && model.sql !== '') {
        try {
          const res = await validateModel({
            account: snowflakeAccount,
            database: selectedDatabase,
            sql: model.sql,
          }).unwrap();
          console.log(res);
          updatedModels[modelIndex].sqlValidationStatus = 'valid';
        } catch (err: any) {
          console.log(err);
          setFailed(true);
          validationErrors.push(`Model ${model.name} is invalid`);
          updatedModels[modelIndex].sqlValidationStatus = 'invalid';
          updatedModels[modelIndex].sqlValidationErrorMessage = err.data as string;
        }
      } else if (model.sql === '') {
        validationErrors.push(`No SQL provided for model ${model.name}`);
        setFailed(true);
        updatedModels[modelIndex].sqlValidationStatus = 'invalid';
      }
    }

    setLoading(false);
    setErrorMessages(validationErrors);
    dispatch(setModels(updatedModels));

    if (validationErrors.length === 0) {
      setFailed(false);
      setIsValidateAllCompleted(true);
    }
  };

  const onContinue = async (): Promise<void> => {
    if (models === undefined) {
      return;
    }

    setLoading(true);
    setErrorMessages([]);
    setFailed(false);

    if (sqlPaths !== undefined) {
      for (const sqlPath of sqlPaths) {
        const model = models.find((m) => m.path === sqlPath);
        if (model === undefined) {
          try {
            const res = await deleteModel({
              projectPath,
              branch,
              dbtPath: sqlPath,
            }).unwrap();
            console.log(res);
          } catch (err) {
            console.log(err);
            setFailed(true);
            setLoading(false);
            return;
          }
        }
      }
    }

    for (const model of models) {
      if (model.sql !== undefined && model.sql !== '') {
        try {
          const res = await setModel({
            projectPath,
            branch,
            modelPath: model.path,
            materialization: model.materialization,
            dataProductName,
            sql: model.sql,
            defaultDatabase: selectedDatabase,
          }).unwrap();
          console.log(res);
        } catch (err) {
          console.log(err);
          setFailed(true);
          setLoading(false);
          return;
        }
      }
    }

    try {
      const res = await generateDbtSources({
        projectPath,
        branch,
        dataProductName,
        account: snowflakeAccount,
        database: selectedDatabase,
      }).unwrap();
      console.log(res);
    } catch (err) {
      console.log(err);
      setErrorMessages(['Failed to generate dbt sources']);
      setFailed(true);
      setLoading(false);
      return;
    }

    setLoading(false);
    setFailed(false);

    props.onContinue();
  };

  return (
    <StepWrapper
      title="SQL Model Builder"
      subtitle="Transform your SQL queries into powerful, scalable data models with ease"
      onBack={() => props.onBack()}
      onContinue={() => {
        onContinue().catch((err) => console.log(err));
      }}
      isLoading={loading}
      continueDisabled={selectedDatabase === ''}
      {...(!isValidateAllCompleted && {
        customButtonLabel: 'Validate All',
        customButtonAction: () => {
          onValidateAll().catch((err) => console.log(err));
        },
      })}
    >
      <div className="w-full grid grid-cols-2 gap-4 px-8 wide:px-12 max-w-[70rem] mx-[auto]">
        <Field as="div" className="col-span-2 flex items-center justify-center pb-2">
          <Switch
            checked={reviewedByInformationArchitect}
            onChange={() => dispatch(setReviewedByInformationArchitect(!reviewedByInformationArchitect))}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
          >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
            <span
              aria-hidden="true"
              className={classNames(
                reviewedByInformationArchitect ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
              )}
            />
            <span
              aria-hidden="true"
              className={classNames(
                reviewedByInformationArchitect ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Label as="span" className="ml-1 cursor-default flex">
            <span className="text-sm font-medium text-gray-700">Reviewed by Information Architect</span>
          </Label>
        </Field>
        <div className="col-span-2 xl:col-span-1">
          <h3 className="font-semibold text-gray-800">Select a database</h3>
          <AzDatabaseSelector
            onSelect={(database: string) => dispatch(setSelectedDatabase(database))}
            selectedDatabases={[selectedDatabase]}
            onlyShowDatabases={true}
          />
        </div>
        <div className="col-span-2 xl:col-span-1">
          <div className="flex justify-between px-4 mb-2">
            <h3 className="font-semibold text-gray-800">Models</h3>
            <button
              type="button"
              onClick={() => onCreateNewModel()}
              className="rounded-full bg-dataops-secondary-blue p-1 text-white shadow-sm hover:bg-hover-secondary-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-400"
              disabled={selectedDatabase === ''}
            >
              <PlusIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </div>
          {models !== undefined && models.length > 0 && (
            <ul role="list" className="divide-y divide-gray-100 cursor-pointer">
              {models?.map((model, modelIndex) => (
                <li
                  key={modelIndex}
                  onClick={() => setSelectedModelIndex(modelIndex)}
                  className={classNames(
                    modelIndex === selectedModelIndex ? 'bg-gray-50' : 'bg-white',
                    'relative flex items-center px-4 py-1 text-gray-700 hover:bg-gray-50 rounded-md',
                  )}
                >
                  <input
                    id="first-option"
                    name="deployment"
                    type="radio"
                    checked={selectedModelIndex === modelIndex}
                    onChange={() => {
                      setValidationInProgress(false);
                      setSelectedModelIndex(modelIndex);
                    }}
                    className="w-3 h-3 rounded border-gray-300 mr-2"
                  />
                  <div
                    className={classNames(
                      model.modelNameValidationStatus === 'invalid' ? 'text-red-600' : 'text-gray-700',
                      'text-sm truncate',
                    )}
                  >
                    {model.name}
                  </div>
                  {model.sqlValidationStatus === 'invalid' && (
                    <ModelErrorIcon tooltip="The SQL query for this model is not valid" />
                  )}
                  <button type="button" className="ml-[auto] rounded-full text-dataops-secondary-blue p-1 shadow-sm">
                    <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteModel(model);
                    }}
                    className="rounded-full text-dataops-red p-1 shadow-sm"
                  >
                    <TrashIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </li>
              ))}
            </ul>
          )}
          {models !== undefined && models.length === 0 && !isLoading && (
            <div className="mt-4 px-4 text-base text-gray-600">No models</div>
          )}
        </div>
        {models !== undefined &&
          models.length !== 0 &&
          selectedModelIndex !== undefined &&
          selectedModelIndex < models.length && (
            <>
              <div className="col-span-2 xl:col-span-1">
                <label htmlFor="version" className="block leading-6 text-gray-800 font-semibold">
                  Selected model
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                    <input
                      type="text"
                      id="version"
                      name="version"
                      value={models[selectedModelIndex].name}
                      onChange={(e) => onChangeModelName(e.target.value)}
                      autoComplete="modelName"
                      placeholder="Model name"
                      required
                      className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2 xl:col-span-1 pl-3">
                <label htmlFor="version" className="block leading-6 text-gray-800 font-semibold">
                  Materialization
                </label>
                <DropdownSingleselect
                  label=""
                  options={['virtualized', 'materialized', 'incremental']}
                  selected={models[selectedModelIndex].materialization}
                  setSelected={(value: string) => onChangeMaterialization(value)}
                />
              </div>
              <div className="col-span-2 w-3/4 max-w-[50rem] mx-[auto] mt-4">
                <label className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 py-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <input type="file" accept=".sql" onChange={onFileChange} className="hidden" />
                  {filename === undefined && (
                    <span className="flex items-center justify-center text-sm text-gray-500">Upload a SQL file</span>
                  )}
                  {filename !== undefined && (
                    <div className="flex items-center justify-center">
                      <DocumentIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                      <span className="flex items-center justify-center text-sm text-gray-500">{filename}</span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-span-2 text-sm text-gray-600 text-center">or paste the SQL in the field below</div>
              <div className="relative col-span-2 w-3/4 max-w-[50rem] mx-[auto]">
                <div className="rounded-md shadow-sm bg-dataops-secondary-blue p-[2px] pl-[1.8rem]">
                  <CodeEditor
                    id="sql"
                    name="sql"
                    language="sql"
                    minHeight={200}
                    data-color-mode="light"
                    value={models[selectedModelIndex].sql}
                    onChange={(e) => {
                      onChangeModelSql(e.target.value);
                      setValidationInProgress(false);
                    }}
                    rows={8}
                    required
                    className="bg-transparent rounded-r block w-full border-0 text-sm text-black placeholder:text-gray-400"
                    placeholder="Paste your SQL here"
                    style={{ overflow: 'visible' }}
                  />
                </div>
                {models[selectedModelIndex].sql !== '' && (
                  <>
                    {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'invalid' && (
                      <button
                        type="button"
                        className="absolute bottom-2 right-[6.2rem] rounded-full p-1.5 bg-white ring-1 ring-inset ring-red-600 text-red-500 cursor-pointer hover:text-red-400 hover:ring-red-400"
                        onClick={() => {
                          onCopy().catch((err) => console.log(err));
                        }}
                      >
                        {copySuccess ? (
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        ) : (
                          <ClipboardIcon className="h-4 w-4" aria-hidden="true" />
                        )}
                      </button>
                    )}
                    <button
                      type="button"
                      className={classNames(
                        !validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'valid'
                          ? 'ring-green-600 cursor-default'
                          : !validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'invalid'
                          ? 'ring-red-600 cursor-default'
                          : 'ring-gray-300 cursor-pointer hover:bg-gray-50',
                        'absolute w-[5.5rem] h-7 bottom-2 right-2 rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset flex items-center justify-center',
                      )}
                      onClick={() => {
                        validateModelSql(models[selectedModelIndex].sql as string).catch((err) => console.log(err));
                      }}
                    >
                      {validationInProgress && (
                        <div className="animate-spin inline-block w-4 h-4 border-[1px] border-current border-t-transparent text-gray-900 rounded-full">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'unevaluated' && (
                        <span>Validate</span>
                      )}
                      {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'valid' && (
                        <span className="text-green-700">Valid</span>
                      )}
                      {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'invalid' && (
                        <span className="text-red-500 flex relative">
                          <div>Invalid</div>
                          <div className="absolute right-[-14px]">
                            <InformationTooltip
                              isRed={true}
                              tooltip={models[selectedModelIndex].sqlValidationErrorMessage as string}
                            />
                          </div>
                        </span>
                      )}
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        <div className="col-span-2 w-3/4 max-w-[50rem] mx-[auto]">
          <LoadingAndErrorSection
            isLoading={loading}
            isFailed={failed}
            errorMessage={errorMessages}
            hideLoading={true}
          />
        </div>
        {isValidateAllCompleted && !loading && !failed && (
          <div className="col-span-2 w-3/4 max-w-[50rem] mx-[auto] py-1 px-1.5 text-sm rounded bg-green-50 border-2 border-green-500 text-green-600 flex items-center justify-center">
            Validation passed for all models
          </div>
        )}
      </div>
    </StepWrapper>
  );
}
