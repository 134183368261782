import React, { useState, useEffect } from 'react';
import { useGetGroupsQuery, useGetProjectsQuery, useGetProjectsFromGroupQuery } from 'app/platformApi';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import ComboboxAutocomplete from 'components/ComboboxAutocomplete';
import type { IOption } from 'components/ComboboxAutocomplete';
import type { IPlatformProject } from 'utils/platformApiTypes';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'utils/styleUtils';

export default function ProjectSearchView(props: {}): JSX.Element {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [groupSearchQuery, setGroupSearchQuery] = useState<string>('');

  const [selectedGroup, setSelectedGroup] = useState<IOption>({ name: '', id: 0, path: '' });
  const [filteredProjects, setFilteredProjects] = useState<IPlatformProject[]>([]);

  const { data: projects, isLoading } = useGetProjectsQuery({ search: searchQuery });
  const { data: groups } = useGetGroupsQuery({ search: groupSearchQuery });
  const { data: projectsFromGroup, isLoading: isLoadingProjectsFromGroup } = useGetProjectsFromGroupQuery(
    { groupId: selectedGroup.id, search: searchQuery },
    { skip: selectedGroup.id === 0 },
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (projects !== undefined && selectedGroup.id !== 0) {
      setFilteredProjects(projectsFromGroup ?? []);
    } else {
      setFilteredProjects(projects ?? []);
    }
  }, [projects, selectedGroup, projectsFromGroup]);

  return (
    <>
      {/* <div className="w-full max-w-[30rem] relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
        </div>
        <input
          id="search"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search projects"
          className="w-full block rounded-md border-0 py-2.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div> */}
      <div className="w-full max-w-[70rem] flex flex-row gap-2">
        <div className="h-[33px] relative flex flex-1 rounded-md">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
          <input
            className="h-[33px] w-2/3 bg-white border border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out"
            data-testid="search-input"
            placeholder="Search projects"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="w-1/3">
            <ComboboxAutocomplete
              label=""
              options={(groups as IOption[]) ?? []}
              selected={selectedGroup}
              setSelected={(group: IOption) => setSelectedGroup(group)}
              query={groupSearchQuery}
              setQuery={setGroupSearchQuery}
              hideLabel={true}
              placeholder="Filter by group"
              showClearButton={true}
            />
          </div>
        </div>
      </div>
      <div className="w-full max-w-[70rem]">
        {filteredProjects !== undefined && filteredProjects.length === 20 && (
          <div className="mt-8 mr-[auto] mb-2 text-sm text-gray-600">Showing the first 20 projects</div>
        )}
        {filteredProjects !== undefined && filteredProjects.length < 20 && filteredProjects.length > 0 && (
          <div className="mt-8 mr-[auto] mb-2 text-sm text-gray-600">{filteredProjects.length} projects</div>
        )}
        {filteredProjects !== undefined && filteredProjects.length === 0 && (
          <div className="mt-8 mr-[auto] mb-2 text-sm text-gray-600">No projects found</div>
        )}
      </div>
      <ul role="list" className="w-full max-w-[70rem] mx-[auto] grid grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-3 pb-8">
        {filteredProjects?.map((project) => (
          <li
            key={project.id}
            onClick={() => navigate(`/astra-zeneca-monitoring/status/${project.id}`)}
            className="cursor-pointer overflow-hidden rounded-xl border border-gray-300 bg-white hover:border-gray-400 hover:shadow-md"
          >
            <div className="flex items-center gap-x-2 border-b border-gray-900/5 p-3">
              <div
                className={classNames(
                  project.avatar_url !== null ? 'bg-white border border-gray-300' : 'bg-dataops-primary-blue',
                  'w-6 h-6 rounded-full object-contain overflow-hidden flex items-center justify-center flex-shrink-0',
                )}
              >
                {project.avatar_url !== null ? (
                  <img className="p-0.5" src={project.avatar_url} />
                ) : (
                  <div className="rounded-full text-white font-medium">{project.name.charAt(0).toUpperCase()}</div>
                )}
              </div>
              <div className="text-base font-medium leading-6 text-gray-900 truncate">{project.name}</div>
            </div>
            <dl className="divide-y divide-gray-100 px-3 py-1.5 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-1">
                <dt className="text-gray-500 whitespace-nowrap">Project path</dt>
                <dd className="text-gray-700 truncate">{project.namespace.full_path}</dd>
              </div>
              <div className="flex justify-between gap-x-4 py-1">
                <dt className="text-gray-500 whitespace-nowrap">Last modified</dt>
                <dd className="text-gray-700 truncate">
                  <time dateTime="2024-10-10">
                    {`${new Date(project.last_activity_at).toLocaleString('en-US', { month: 'short' })} ${new Date(
                      project.last_activity_at,
                    ).toLocaleString('en-US', { day: 'numeric' })}, ${new Date(
                      project.last_activity_at,
                    ).getFullYear()}`}
                  </time>
                </dd>
              </div>
            </dl>
          </li>
        ))}
      </ul>
      {(isLoading || isLoadingProjectsFromGroup) && (
        <div className="min-h-8 flex items-center justify-center">
          <div
            className="animate-spin inline-block w-8 h-8 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
}
