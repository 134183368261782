import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/baseQuery';
import { getConfig } from 'config/config-helper';
import type {
  IPlatformGroup,
  IPlatformProject,
  IPlatformBranch,
  IProjectPipeline,
  IPlatformMergeRequest,
  IPlatformMergeRequestApproval,
  IPlatformMergeRequestDiscussion,
} from 'utils/platformApiTypes';

const { dataopsliveBaseUrl } = getConfig();

export const platformApi = createApi({
  reducerPath: 'platformApi',
  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: `${dataopsliveBaseUrl}/api/v4`,
    }),
    {
      retryCondition: (e: any, __, { attempt }) => {
        if (e.originalStatus !== undefined && e.originalStatus >= 500 && e.originalStatus < 600 && attempt <= 3) {
          return true;
        }
        return false;
      },
    },
  ),
  keepUnusedDataFor: 600,
  tagTypes: ['MergeRequests'],
  endpoints: (builder) => ({
    getGroups: builder.query<IPlatformGroup[], { search: string }>({
      query: (params) => ({
        url: '/groups',
        params: { ...params, min_access_level: 30, order_by: 'similarity' },
      }),
      providesTags: [],
    }),
    getProjects: builder.query<IPlatformProject[], { search: string }>({
      query: (params) => ({
        url: '/projects',
        params: { ...params, order_by: params.search === '' ? 'updated_at' : 'similarity', min_access_level: 30 },
      }),
      providesTags: [],
    }),
    getProject: builder.query<IPlatformProject, { projectId: string }>({
      query: (params) => ({
        url: `/projects/${params.projectId}`,
        params: {},
      }),
      providesTags: [],
    }),
    getProjectsFromGroup: builder.query<IPlatformProject[], { groupId: number; search: string }>({
      query: (params) => ({
        url: `/groups/${params.groupId}/projects`,
        params: { ...params, order_by: params.search === '' ? 'updated_at' : 'similarity', min_access_level: 30 },
      }),
      providesTags: [],
    }),
    getBranches: builder.query<IPlatformBranch[], { projectId: string }>({
      query: (params) => ({
        url: `/projects/${params.projectId}/repository/branches`,
        params: {},
      }),
      providesTags: [],
    }),
    getLatestPipeline: builder.query<IProjectPipeline[], { projectId: string; branch: string; numPipelines: number }>({
      query: (params) => ({
        url: `/projects/${params.projectId}/pipelines?ref=${params.branch}&per_page=${params.numPipelines}`,
        params: {},
      }),
      providesTags: [],
    }),
    getMergeRequests: builder.query<IPlatformMergeRequest[], { projectId: string }>({
      query: (params) => ({
        url: `/projects/${params.projectId}/merge_requests`,
        params: {},
      }),
      providesTags: ['MergeRequests'],
    }),
    createMergeRequest: builder.mutation({
      query: ({ projectId, sourceBranch, targetBranch, title, description }) => ({
        url: `/projects/${projectId as string}/merge_requests`,
        method: 'POST',
        params: { source_branch: sourceBranch, target_branch: targetBranch, title, description },
      }),
      invalidatesTags: ['MergeRequests'],
    }),
    approveMergeRequest: builder.mutation({
      query: ({ projectId, mergeRequestId }) => ({
        url: `/projects/${projectId as string}/merge_requests/${mergeRequestId as string}/approve`,
        method: 'POST',
        params: {},
      }),
      invalidatesTags: ['MergeRequests'],
    }),
    addMergeRequestNote: builder.mutation({
      query: ({ projectId, mergeRequestId, comment }) => ({
        url: `/projects/${projectId as string}/merge_requests/${mergeRequestId as string}/notes`,
        method: 'POST',
        params: { body: comment },
      }),
      invalidatesTags: [],
    }),
    getMergeRequestsApprovals: builder.query<
      IPlatformMergeRequestApproval,
      { projectId: string; mergeRequestId: number }
    >({
      query: (params) => ({
        url: `/projects/${params.projectId}/merge_requests/${params.mergeRequestId}/approvals`,
        params: {},
      }),
      providesTags: ['MergeRequests'],
    }),
    getMergeRequestDiscussions: builder.query<
      IPlatformMergeRequestDiscussion[],
      { projectId: string; mergeRequestId: number }
    >({
      query: (params) => ({
        url: `/projects/${params.projectId}/merge_requests/${params.mergeRequestId}/discussions?per_page=100`,
        params: {},
      }),
      providesTags: ['MergeRequests'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetProjectsQuery,
  useGetProjectsFromGroupQuery,
  useGetProjectQuery,
  useGetBranchesQuery,
  useGetLatestPipelineQuery,
  useLazyGetLatestPipelineQuery,
  useGetMergeRequestsQuery,
  useCreateMergeRequestMutation,
  useApproveMergeRequestMutation,
  useAddMergeRequestNoteMutation,
  useLazyGetMergeRequestsApprovalsQuery,
  useLazyGetMergeRequestDiscussionsQuery,
} = platformApi;
