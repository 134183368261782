import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectSpcsMinNodes,
  selectSpcsMaxNodes,
  selectSpcsInstanceFamily,
  selectSpcsDescription,
  setSpcsMinNodes,
  setSpcsMaxNodes,
  setSpcsInstanceFamily,
  setSpcsDescription,
} from '../../native-app-builder/reducers/nativeAppSlice';
import StepWrapper from 'components/StepWrapper';
import DropdownSingleselect from 'components/DropdownSingleselect';
import InfoSection from 'components/InfoSection';

const instanceFamilies = [
  'CPU | XS',
  'CPU | S',
  'CPU | M',
  'CPU | L',
  'High-Memory CPU | XS',
  'High-Memory CPU | S',
  'High-Memory CPU | M',
  'High-Memory CPU | L',
  'GPU | XS',
  'GPU | S',
  'GPU | M',
  'GPU | L',
];

export interface SpcsConfigurationStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SpcsConfigurationStep(props: SpcsConfigurationStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const minNodes = useAppSelector(selectSpcsMinNodes);
  const maxNodes = useAppSelector(selectSpcsMaxNodes);
  const instanceFamily = useAppSelector(selectSpcsInstanceFamily);
  const spcsDescription = useAppSelector(selectSpcsDescription);

  return (
    <StepWrapper
      title="Snowpark Container Service configuration"
      subtitle="Provide the Snowpark Container Service configuration"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full">
        <form className="w-full pb-2">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-4">
            {/* Compute pool configuration */}
            <div className="relative col-span-2 mt-2">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Compute pool configuration</span>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label htmlFor="monNodes" className="block text-sm font-medium leading-6 text-gray-900">
                Min nodes
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="number"
                    value={minNodes}
                    onChange={(e) => dispatch(setSpcsMinNodes(Number(e.target.value)))}
                    name="minNodes"
                    id="minNodes"
                    autoComplete="minNodes"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="APP_ROLE"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Max nodes
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="number"
                    value={maxNodes}
                    onChange={(e) => dispatch(setSpcsMaxNodes(Number(e.target.value)))}
                    name="maxNodes"
                    id="maxNodes"
                    autoComplete="maxNodes"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="COMPUTE_WH"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <DropdownSingleselect
                label="Instance family"
                options={instanceFamilies}
                selected={instanceFamily}
                setSelected={(value) => dispatch(setSpcsInstanceFamily(value))}
              />
            </div>
          </div>
        </form>

        <form className="w-full pb-6 mt-4">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2 mb-4">
                <textarea
                  id="decription"
                  name="description"
                  value={spcsDescription}
                  onChange={(e) => dispatch(setSpcsDescription(e.target.value))}
                  rows={5}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  required
                />
              </div>
              <InfoSection
                text="Give a description of what you want to achieve with this component. DataOps.live Assist will
                then automatically generate code to getting you started in the right direction"
              />
            </div>
          </div>
        </form>
        <div className="relative w-3/4 mx-[auto] col-span-2 mt-2 mb-4">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-400" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-600">Upload</span>
          </div>
        </div>
        <div className="w-full pb-8 flex flex-col items-center justify-center">
          <p className="w-3/4 text-sm text-gray-600">
            Already developed a containerised service? Click Upload and attach a minimum of a Dockerfile, but you can
            also upload multiple files, or a ZIP file with a full directory structure.
          </p>
          <button className="w-3/4 max-w-[20rem] mt-6 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
            Upload
          </button>
        </div>
      </div>
    </StepWrapper>
  );
}
