import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TargetAudienceBadge from 'components/TargetAudienceBadge';
import NotAvailableFlowButton from 'components/NotAvailableFlowButton';
import { persistor } from 'app/store';
import { classNames } from 'utils/styleUtils';
import { sendMetrics, MetricType } from 'utils/metrics';
import { selectUser, selectEmail } from 'features/authentication/reducers/userSlice';
import { useAppSelector } from 'app/hooks';

interface IFlow {
  name: string;
  type: MetricType;
  description: string;
  targetAudience: string[];
  disabled: boolean;
  hidden?: boolean;
  link: string;
}

export default function ChooseFlow(): JSX.Element {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const email = useAppSelector(selectEmail);
  const [flows, setFlows] = useState<IFlow[]>([]);

  const deploymentEnv = process.env.REACT_APP_ENV;

  const showAZFlow = useMemo(
    () =>
      (deploymentEnv === 'dev' || deploymentEnv === 'qa' || deploymentEnv === 'az') &&
      (email.endsWith('@astrazeneca.com') || email.endsWith('@dataops.live') || email.endsWith('@imaginarycloud.com')),
    [email, deploymentEnv],
  );

  useEffect(() => {
    setFlows([
      {
        name: 'Analytical Data Product',
        type: MetricType.create_analytics,
        description:
          'You can connect your snowflake account or bring your own SQL to create your first end to end data product in minutes. Application will guide you through all the steps starting from defining your data product, configuring your snowflake account, confirming auto generated tests and working with your personal AI Assistant to help you transform your data and publish your data product.',
        targetAudience: ['Data Product Owner', 'Data Analyst'],
        disabled: false,
        link: '/data-product-creator',
      },
      {
        name: 'AstraZeneca Flow',
        type: MetricType.create_analytics,
        description:
          'You can connect your snowflake account or bring your own SQL to create your first end to end data product in minutes. Application will guide you through all the steps starting from defining your data product, configuring your snowflake account, confirming auto generated tests and working with your personal AI Assistant to help you transform your data and publish your data product.',
        targetAudience: ['Data Product Owner', 'Data Analyst'],
        disabled: false,
        hidden: !showAZFlow,
        link: '/astra-zeneca-flow',
      },
      {
        name: 'AstraZeneca Monitoring',
        type: MetricType.create_analytics,
        description: 'Monitor the status of the pipelines and merge requests in your Data Products.',
        targetAudience: ['Data Product Owner', 'Data Analyst'],
        disabled: false,
        hidden: !showAZFlow,
        link: '/astra-zeneca-monitoring',
      },
      {
        name: 'Snowflake Native App',
        type: MetricType.create_native_app,
        description:
          'Create and deploy a Snowflake Native App in minutes. Everything from sql to Snowpark, Streamlit to Cortex and even Container Services - automatically build, test and deploy Native Apps at the click of a button, including test subscriptions.',
        targetAudience: ['Snowflake Developer', 'DataOps Developer', 'Data Scientist', 'Native App Developer'],
        disabled: false,
        link: '/native-app-builder',
      },
      {
        name: 'dbt\u2122 Quickstart',
        type: MetricType.create_dbt,
        description:
          "Elevate your data projects and data products seamlessly. Follow our guided process to embed your dbt™️ Core project into DataOps.live or link DataOps.live with your dbt™️ Cloud environment. We'll handle any automatic migration and conversion, providing you with all the benefits of dbt™️ and DataOps.live working seamlessly together.",
        targetAudience: ['Data Engineer', 'DataOps Developer'],
        disabled: false,
        link: '/dbt-migration',
      },
      {
        name: 'Data App Builder',
        type: MetricType.create_native_app,
        description:
          'Create and deploy Snowflake components in minutes. Everything from sql to Snowpark, Streamlit to Cortex and even Container Services - automatically build, test and deploy components at the click of a button, including test subscriptions.',
        targetAudience: ['Snowflake Developer', 'DataOps Developer', 'Data Scientist'],
        disabled: false,
        hidden: deploymentEnv === 'production',
        link: '/data-app-builder',
      },
      {
        name: 'Snowflake Solution Central',
        type: MetricType.create_native_app,
        description:
          'Create Snowflake Solution Central project in a few clicks. This can be to either create new SSC Solutions or import an existing SSC Solution that you have created outside of Snowflake Solution Central.',
        targetAudience: ['SSC Producer'],
        disabled: false,
        hidden: deploymentEnv === 'production',
        link: '/snowflake-solution-central',
      },
      {
        name: 'Data Science Data Product',
        type: MetricType.create_data_science,
        description:
          'Master the creation of DataOps pipelines tailored for Data Science and Machine Learning. Ensure your LLM trainers are fueled with the latest data, and your models stay ahead with current forecasts. Streamline your ML workflows for peak efficiency and accuracy.',
        targetAudience: ['Data Engineer', 'Data Scientist'],
        disabled: true,
        link: '/',
      },
      {
        name: 'Streamlit Data Product',
        type: MetricType.create_streamlit,
        description:
          'Streamline your journey to production with our guided flow, simplifying the creation of your first DataOps pipeline for Streamlit data applications. Perfect for Snowflake users aiming to enhance data accessibility and insights without extensive web development experience.',
        targetAudience: ['Snowflake Developer', 'DataOps Developer'],
        disabled: true,
        hidden: deploymentEnv === 'production',
        link: '/',
      },
    ]);
  }, [showAZFlow]);

  return (
    <div className="my-4 mx-14 pb-6 grid grid-cols-1 sm:grid-cols-2 wide:grid-cols-3 gap-4 mt-[4.9rem]">
      {flows
        .filter((flow) => flow.hidden !== true)
        .map((flow) => (
          <div
            key={flow.name}
            className={classNames(
              flow.disabled ? 'bg-gray-50' : 'bg-white',
              'relative rounded-md drop-shadow-md p-6 sm:p-8 flex flex-col items-start',
            )}
          >
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">{flow.name}</h3>
            <div className="mt-2 flex flex-wrap">
              {flow.targetAudience.map((audience) => (
                <TargetAudienceBadge key={audience} label={audience} />
              ))}
            </div>
            <p className="mt-4 mb-8 text-base leading-7 text-gray-600">{flow.description}</p>
            {!flow.disabled && (
              <button
                className="absolute bottom-6 right-8 min-w-[8rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400"
                onClick={() => {
                  sendMetrics(flow.type, user, '0', null);
                  persistor
                    .purge()
                    .then((res) => {
                      navigate(flow.link);
                      console.log('purge: ', res);
                    })
                    .catch((err) => {
                      console.log('purge error: ', err);
                    });
                }}
              >
                Select
              </button>
            )}
            {flow.disabled && <NotAvailableFlowButton />}
          </div>
        ))}
    </div>
  );
}
