import React from 'react';
import TestEditorModelItem from './TestEditorModelItem';
import type { IModelsFileContent, ITest, IModelAndColumn } from '../types/testEditorTypes';

interface TestEditorProps {
  modelsFileContent: IModelsFileContent | undefined;
  openDialog: (value: boolean) => void;
  selectedModelsAndColumns: IModelAndColumn[];
  setSelectedModelsAndColumns: (value: IModelAndColumn[]) => void;
  setExistingTest: (value: ITest | undefined) => void;
  onRemoveTest: (modelName: string, columnName: string, test: ITest) => void;
}

const TestEditor = (props: TestEditorProps): JSX.Element => {
  if (
    props.modelsFileContent === undefined ||
    props.modelsFileContent.models === undefined ||
    props.modelsFileContent.models.length === 0
  ) {
    return <></>;
  }

  return (
    <div className="w-full max-w-[35rem] mt-1 mb-1 flex flex-col items-center space-y-2">
      {props.modelsFileContent.models?.map((model) => (
        <TestEditorModelItem
          key={model.name}
          model={model}
          openDialog={props.openDialog}
          selectedModelsAndColumns={props.selectedModelsAndColumns}
          setSelectedModelsAndColumns={props.setSelectedModelsAndColumns}
          setExistingTest={props.setExistingTest}
          onRemoveTest={props.onRemoveTest}
        />
      ))}
    </div>
  );
};

export default TestEditor;
