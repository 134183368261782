import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectSnowflakeUsername,
  selectSnowflakeWarehouse,
  selectSnowflakeRole,
  selectSnowflakeAccountConnected,
  selectSnowflakePrivateKey,
  selectSnowflakePassphrase,
  setSnowflakeUsername,
  setSnowflakeWarehouse,
  setSnowflakeRole,
  setSnowflakeAccountConnected,
  setSnowflakePrivateKey,
  setSnowflakePassphrase,
  setSnowflakeAccountLabel,
  selectSnowflakeAccountLabel,
} from 'app/snowflakeSlice';
import StepWrapper from 'components/StepWrapper';
import SnowflakeAccountForm from 'components/SnowflakeAccountForm';

export interface MetadataImportStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SnowflakeConnectionStep(props: MetadataImportStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const snowflakeAccountLabel = useAppSelector(selectSnowflakeAccountLabel);
  const snowflakeUsername = useAppSelector(selectSnowflakeUsername);
  const snowflakeWarehouse = useAppSelector(selectSnowflakeWarehouse);
  const snowflakeRole = useAppSelector(selectSnowflakeRole);
  const snowflakeAccountConnected = useAppSelector(selectSnowflakeAccountConnected);
  const snowflakePrivateKey = useAppSelector(selectSnowflakePrivateKey);
  const snowflakePassphrase = useAppSelector(selectSnowflakePassphrase);

  const onContinue = (): void => {
    props.onContinue();
  };

  return (
    <StepWrapper
      title="Connect to your Snowflake account"
      subtitle="Select to have the metadata from an existing Snowflake account through live interaction"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      continueDisabled={!snowflakeAccountConnected}
    >
      <div className="w-full flex flex-col items-center">
        <SnowflakeAccountForm
          snowflakeAccount={snowflakeAccountLabel}
          setSnowflakeAccount={(account) => dispatch(setSnowflakeAccountLabel(account))}
          snowflakeUsername={snowflakeUsername}
          setSnowflakeUsername={(username) => dispatch(setSnowflakeUsername(username))}
          snowflakeWarehouse={snowflakeWarehouse}
          setSnowflakeWarehouse={(warehouse) => dispatch(setSnowflakeWarehouse(warehouse))}
          snowflakeRole={snowflakeRole}
          setSnowflakeRole={(role) => dispatch(setSnowflakeRole(role))}
          snowflakeAccountConnected={snowflakeAccountConnected}
          setSnowflakeAccountConnected={(connected) => dispatch(setSnowflakeAccountConnected(connected))}
          snowflakePrivateKey={snowflakePrivateKey}
          setSnowflakePrivateKey={(key) => dispatch(setSnowflakePrivateKey(key))}
          snowflakePassphrase={snowflakePassphrase}
          setSnowflakePassphrase={(passphrase) => dispatch(setSnowflakePassphrase(passphrase))}
        />
      </div>
    </StepWrapper>
  );
}
