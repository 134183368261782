import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectStreamlitDeploymentOption,
  selectStreamlitDescription,
  setStreamlitDeploymentOption,
  setStreamlitDescription,
} from '../reducers/nativeAppSlice';
import StepWrapper from 'components/StepWrapper';
import InfoSection from 'components/InfoSection';
import ComingSoonBadge from 'components/ComingSoonBadge';

export interface StreamlitConfigurationStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function StreamlitConfigurationStep(props: StreamlitConfigurationStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const streamlitDeploymentOption = useAppSelector(selectStreamlitDeploymentOption);
  const streamlitDescription = useAppSelector(selectStreamlitDescription);

  return (
    <StepWrapper
      title="Streamlit configuration"
      subtitle="Provide the Streamlit configuration"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full flex flex-col items-center">
        <fieldset className="w-3/4 max-w-[30rem] mt-6 mb-8">
          <div className="divide-y divide-gray-200">
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="first-option" className="font-medium text-gray-900">
                  Streamlit in Snowflake (SIS)
                </label>
                <p className="text-gray-500"></p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="first-option"
                  name="streamlit"
                  type="radio"
                  checked={streamlitDeploymentOption === 'sis'}
                  onChange={() => dispatch(setStreamlitDeploymentOption('sis'))}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="flex space-x-2 font-medium text-gray-600">
                  <div>Streamlit standalone in SPCS</div>
                  <ComingSoonBadge />
                </label>
                <p className="text-gray-500"></p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="second-option"
                  name="streamlit"
                  type="radio"
                  checked={streamlitDeploymentOption === 'standalone'}
                  onChange={() => dispatch(setStreamlitDeploymentOption('standalone'))}
                  className="w-5 h-5 rounded border-gray-300"
                  disabled
                />
              </div>
            </div>
          </div>
        </fieldset>

        <form className="w-full pb-6">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2 mb-4">
                <textarea
                  id="decription"
                  name="description"
                  value={streamlitDescription}
                  onChange={(e) => dispatch(setStreamlitDescription(e.target.value))}
                  rows={5}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  required
                />
              </div>
              <InfoSection
                text="Give a description of what you want to achieve with this component. DataOps.live Assist will
                then automatically generate code to getting you started in the right direction"
              />
            </div>
          </div>
        </form>
        {/* --- Upload section --- */}
        {/* <div className="relative w-3/4 mx-[auto] col-span-2 mt-2 mb-4">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-400" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-600">Upload</span>
          </div>
        </div>
        <div className="w-full pb-8 flex flex-col items-center justify-center">
          <p className="w-3/4 text-sm text-gray-600">
            Already developed a streamlit app? Click Upload and attach a minimum of a Main.py, but you can also upload
            multiple files, or a ZIP file with a full directory structure.
          </p>
          <button className="w-3/4 max-w-[20rem] mt-6 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
            Upload
          </button>
        </div> */}
      </div>
    </StepWrapper>
  );
}
