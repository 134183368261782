import React from 'react';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from '@headlessui/react';

import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import InformationTooltip from 'components/InformationTooltip';
import { classNames } from 'utils/styleUtils';

export interface IOption {
  name: string;
  id: number;
  path: string;
}

interface ComboboxAutocompleteProps {
  label: string;
  options: IOption[];
  selected: IOption;
  setSelected: (value: IOption) => void;
  query: string;
  setQuery: (value: string) => void;
  tooltip?: string;
  hideLabel?: boolean;
  placeholder?: string;
  showClearButton?: boolean;
}

const ComboboxAutocomplete = (props: ComboboxAutocompleteProps): JSX.Element => {
  return (
    <Field className="w-full">
      {props.hideLabel !== true && (
        <Label className="mb-1 text-sm font-medium leading-6 text-gray-900 flex items-center">
          <div>{props.label}</div>
          {props.tooltip !== undefined && <InformationTooltip tooltip={props.tooltip} />}
        </Label>
      )}
      <Combobox immediate value={props.selected} onChange={props.setSelected}>
        <div className="relative w-full cursor-default rounded-md bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 sm:text-sm sm:leading-6 flex items-center">
          <ComboboxInput
            className="w-full py-1.5 pl-1.5 pr-10 text-black bg-transparent border-none text-sm focus:outline-none"
            onChange={(event) => props.setQuery(event.target.value)}
            displayValue={() => {
              if (props.selected !== null) {
                return props.selected.name;
              }
              return '';
            }}
            placeholder={props.placeholder ?? ''}
            required
          />
          <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5 cursor-pointer">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor="bottom"
          transition
          className="absolute z-20 mt-1 max-h-60 w-[22rem] xl:w-[20rem] overflow-auto rounded-md bg-white pt-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {props.options.length === 0 && props.query !== '' && props.query.length <= 2 && (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
              Please type at least 3 characters
            </div>
          )}
          {props.options.length === 0 && props.query !== '' && props.query.length > 2 && (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
          )}
          {props.options.length !== 0 &&
            props.options.map((option) => (
              <ComboboxOption
                key={option.name}
                value={option}
                className={({ active }) =>
                  classNames(
                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                    'relative cursor-default select-none py-1 pl-3 pr-9',
                  )
                }
              >
                {({ selected, active }) => (
                  <>
                    <span className="block text-xs text-gray-500 truncate">{option.path}</span>
                    <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate')}>
                      {option.name}
                    </span>
                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-gray-600 bg-gray-100' : 'text-gray-600',
                          'absolute inset-y-0 right-0 flex items-center pr-2',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </ComboboxOption>
            ))}
        </ComboboxOptions>
      </Combobox>
    </Field>
  );
};

export default ComboboxAutocomplete;
