import { MiddlewareAPI, Dispatch, Middleware } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

const statusCodeMiddleware: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  const response = action.payload;
  const data = response?.data as string;
  if (response?.status === 302 && response?.data !== null) {
    window.location.replace(`${data}&redirect_url=${window.location.href}`);
  }

  return next(action);
};

export default statusCodeMiddleware;
