import React, { useState, useEffect } from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useApproveMergeRequestMutation, useLazyGetMergeRequestDiscussionsQuery } from 'app/platformApi';
import { useAppSelector } from 'app/hooks';
import { selectUser } from 'features/authentication/reducers/userSlice';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import type { IExtendedPlatformMergeRequest, IExtendedPlatformBranch } from 'utils/platformApiTypes';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 600,
};

interface TooltipData {
  description: string;
}

interface MergeRequestItemProps {
  mergeRequest: IExtendedPlatformMergeRequest;
  extendedBranches: IExtendedPlatformBranch[];
  projectId: string;
  setSelectedMergeRequestId: (id: number) => void;
  setIsCommentOnMRDialogOpen: (isOpen: boolean) => void;
}

export default function MergeRequestItem(props: MergeRequestItemProps): JSX.Element {
  const username = useAppSelector(selectUser);
  const [unresolvedCommentLink, setUnresolvedCommentLink] = useState<string | undefined>(undefined);

  const [approveMergeRequest] = useApproveMergeRequestMutation();
  const [getMergeRequestDiscussions] = useLazyGetMergeRequestDiscussionsQuery();

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const onApproveMR = (mergeRequestId: number): void => {
    approveMergeRequest({ projectId: props.projectId, mergeRequestId }).unwrap().catch(console.error);
  };

  useEffect(() => {
    if (props.mergeRequest.blocking_discussions_resolved) {
      setUnresolvedCommentLink(undefined);
    } else {
      getMergeRequestDiscussions({ projectId: props.projectId, mergeRequestId: props.mergeRequest.iid })
        .unwrap()
        .then((data) => {
          for (const discussion of data) {
            for (const note of discussion.notes) {
              if (!note.resolved) {
                setUnresolvedCommentLink(`${props.mergeRequest.web_url}#note_${note.id}`);
                break;
              }
            }
          }
        })
        .catch(console.error);
    }
  }, [props.mergeRequest.blocking_discussions_resolved]);

  return (
    <>
      <li className="pt-2 w-full grid grid-cols-6 gap-4">
        <div className="col-span-2">
          <a
            target="_blank"
            rel="noreferrer"
            href={props.mergeRequest.web_url}
            className="max-w-full flex items-center w-fit hover:underline"
          >
            <p className="text-sm font-semibold leading-6 text-gray-900 truncate">{props.mergeRequest.title}</p>
            <ArrowTopRightOnSquareIcon className="w-4 min-w-4 pl-0.5" />
          </a>
          <div className="py-2 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <div className="truncate">{props.mergeRequest.source_branch}</div>
            {/* <p>{props.mergeRequest.author.name}</p>
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
              <circle r={1} cx={1} cy={1} />
            </svg>
            <p>
              <time dateTime="2024-10-10">{formatDate(props.mergeRequest.updated_at)}</time>
            </p> */}
          </div>
        </div>
        <div
          className="col-span-2 text-sm text-gray-500 mb-[auto] truncate cursor-pointer"
          ref={containerRef}
          onMouseLeave={() => {
            hideTooltip();
          }}
          onMouseMove={(event) => {
            const eventSvgCoords = localPoint(event);
            showTooltip({
              tooltipData: { description: props.mergeRequest.description },
              tooltipTop: eventSvgCoords?.y,
              tooltipLeft: eventSvgCoords?.x,
            });
          }}
        >
          {props.mergeRequest.description}
        </div>
        {/* <div className="col-span-1 flex flex-col items-center justify-center space-y-1">
          <PipelineStatusBadge
            status={
              props.extendedBranches.find((branch) => branch.name === props.mergeRequest.source_branch)?.pipelineStatus
            }
            url={
              props.extendedBranches.find((branch) => branch.name === props.mergeRequest.source_branch)?.pipelineWebUrl
            }
          />
          <div className="flex">
            {props.mergeRequest.approval?.user_has_approved === true && (
              <span className="inline-flex items-center rounded-md bg-blue-50 px-1 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                Approved
              </span>
            )}
            {props.mergeRequest.merge_status === 'can_be_merged' ? (
              <span className="inline-flex items-center rounded-md bg-green-50 px-1 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 cursor-default">
                Mergeable
              </span>
            ) : (
              <span className="inline-flex items-center rounded-md bg-yellow-50 px-1 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 cursor-default">
                Not mergeable
              </span>
            )}
          </div>
        </div> */}
        <div className="col-span-1 flex items-center justify-center">
          {!props.mergeRequest.blocking_discussions_resolved && (
            <a href={unresolvedCommentLink ?? props.mergeRequest.web_url} target="_blank" rel="noreferrer">
              <span className="w-[5.5rem] inline-flex items-center justify-center text-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 cursor-pointer">
                Unresolved Comments
              </span>
            </a>
          )}
          {(props.mergeRequest.blocking_discussions_resolved && props.mergeRequest.merge_status !== 'can_be_merged') ||
            (props.extendedBranches.find((branch) => branch.name === props.mergeRequest.source_branch)
              ?.pipelineStatus === 'failed' && (
              <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 cursor-default">
                Action needed
              </span>
            ))}
        </div>
        <div className="col-span-1 flex items-center justify-center">
          {username !== props.mergeRequest.author.username && (
            <button
              type="button"
              onClick={() => onApproveMR(props.mergeRequest.iid)}
              className="w-[9rem] h-[30px] rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Approve
            </button>
          )}
          {/* <div className="col-span-1">
            <button
              type="button"
              onClick={() => {
                props.setSelectedMergeRequestId(props.mergeRequest.iid);
                props.setIsCommentOnMRDialogOpen(true);
              }}
              className="h-[30px] rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Comment
            </button>
          </div> */}
        </div>
      </li>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
