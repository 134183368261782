import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectNativeAppName,
  selectPackageName,
  selectPackageRole,
  selectPackageWarehouse,
  selectApplicationName,
  selectApplicationRole,
  selectApplicationWarehouse,
  setPackageName,
  setPackageRole,
  setPackageWarehouse,
  setApplicationName,
  setApplicationRole,
  setApplicationWarehouse,
} from '../reducers/nativeAppSlice';
import StepWrapper from 'components/StepWrapper';

export interface NativeModuleConfigurationStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function NativeModuleConfigurationStep(props: NativeModuleConfigurationStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const nativeAppName = useAppSelector(selectNativeAppName);

  const packageName = useAppSelector(selectPackageName);
  const packageRole = useAppSelector(selectPackageRole);
  const packageWarehouse = useAppSelector(selectPackageWarehouse);

  const applicationName = useAppSelector(selectApplicationName);
  const applicationRole = useAppSelector(selectApplicationRole);
  const applicationWarehouse = useAppSelector(selectApplicationWarehouse);

  return (
    <StepWrapper
      title="Native module configuration"
      subtitle="Provide the native module configuration for the native app"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full">
        <form className="w-full pb-6">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-4">
            {/* Native app name */}
            <div className="col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm">
                  <input
                    type="text"
                    value={nativeAppName}
                    readOnly
                    disabled
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            {/* Package details */}
            <div className="relative col-span-2 mt-8">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Package details</span>
              </div>
            </div>
            <div className="col-span-2 text-sm text-gray-600">
              These are the details of the Native App package that will be created, it&apos;s Name and the Role and
              Warehouse that will be used to build it. These should already exist.
            </div>
            <div className="col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={packageName}
                    onChange={(e) => dispatch(setPackageName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="advertising_spend_analysis_pkg"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                Role
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={packageRole}
                    onChange={(e) => dispatch(setPackageRole(e.target.value))}
                    name="role"
                    id="role"
                    autoComplete="role"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="APP_ROLE"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Warehouse
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={packageWarehouse}
                    onChange={(e) => dispatch(setPackageWarehouse(e.target.value))}
                    name="warehouse"
                    id="warehouse"
                    autoComplete="warehouse"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="COMPUTE_WH"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Application details */}
            <div className="relative col-span-2 mt-8">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Application details</span>
              </div>
            </div>
            <div className="col-span-2 text-sm text-gray-600">
              These are the details of the Application that will be created for local testing, it&apos;s Name and the
              Role and Warehouse that will be used to build it. These should also already exist.
            </div>
            <div className="col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={applicationName}
                    onChange={(e) => dispatch(setApplicationName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="advertising_spend_analysis_pkg"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                Role
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={applicationRole}
                    onChange={(e) => dispatch(setApplicationRole(e.target.value))}
                    name="role"
                    id="role"
                    autoComplete="role"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="APP_ROLE"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Warehouse
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={applicationWarehouse}
                    onChange={(e) => dispatch(setApplicationWarehouse(e.target.value))}
                    name="warehouse"
                    id="warehouse"
                    autoComplete="warehouse"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="COMPUTE_WH"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </StepWrapper>
  );
}
